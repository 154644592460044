import { Alert, Button, Flex, Statistic, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AvatarPicker from './AvatarPicker';
import ProfileDropdown from './ProfileDropdown';
import { ArrowDownOutlined, ArrowUpOutlined, BankOutlined, PauseCircleFilled, SyncOutlined } from '@ant-design/icons';
import CountUp from "react-countup";
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'antd/es/layout/layout';
import { get, patch, post, xdelete } from '../services/api';
import { setList } from '../features/list';
import Marquee from 'react-fast-marquee';
import { millisecondsToTime } from './TrackedPicker';
import dayjs from 'dayjs';
import { setCurrentTimeLog } from '../features/currentTimeLog';

let _unsettled = 0;
let last_stored = parseFloat(localStorage.getItem('lastStored') || '0');

const HeaderItem = ({handleWorkStatus}) => {
  const [hideStats, setHideStats] = useState(true);
  const [unsettled_credits, setUnsettledCredits] = useState(0);
  const [settled_credits, setSettledCredits] = useState(0);
  const [unsettled_debits, setUnsettledDebits] = useState(0);
  const [settled_debits, setSettledDebits] = useState(0);
  const [lastUnsettled, setLastUnsettled] = useState(0);
  const [lastStoredAt, setLastStoredAt] = useState(localStorage.getItem('lastStoredAt') || dayjs(Date.now()).format('MMM D, YYYY h:mm A'));
  const currentTaskTimerRef = useRef();
  const currentTimeLog = useSelector((state) => state.currentTimeLog);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users.filter(u => u.id !== user.id));
  const list = useSelector((state)=>state.list);  
  const [readOnly, setReadOnly] = useState(true);
  const dispatch = useDispatch();

  const formatter = (value) => <CountUp duration={5} preserveValue={true} useIndianSeparators={true} prefix="₹" end={value} separator="," decimals={2} useEasing={false} />;

  const refreshTotal = async () => {
    const total = await get('/transactions/total');
    setLastUnsettled(_unsettled);
    setSettledCredits(total.settled_credits[0].balance || 0);
    setUnsettledCredits(total.unsettled_credits[0].balance || 0);
    setSettledDebits(total.settled_debits[0].balance || 0);
    setUnsettledDebits(total.unsettled_debits[0].balance || 0);
    _unsettled = total.unsettled_credits[0].balance;
  }

  const onAddUser = async (user, list, access) => {
    const access_granted = await post(`/lists/${list.id}/list-accesses`, {
      userId: user,
      access
    });
    if(access_granted) {
      dispatch(setList({
        ...list,
        listAccesses: [...(list.listAccesses || []), access_granted]
      }));
    }
  }
  const onRemoveUser = async (user, list) => {
    const access_removed = await xdelete(`/lists/${list.id}/list-accesses`, {
      userId: user
    });
    if(access_removed) {
      dispatch(setList({
        ...list,
        listAccesses: list.listAccesses.filter(la => la.userId !== user)
      }));
    }
  }

  useEffect(()=> {
    refreshTotal();
    if(hideStats) { return; }
    const interval = setInterval(() => {
      refreshTotal();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [hideStats]);
  
  useEffect(()=>{
    if(list && list.id){
      setReadOnly(list.listAccesses.findIndex(la=>(la.userId === user.id && la.access === true) >=0));
    }
  },[list]);

  useEffect(() => {
    if (!currentTimeLog) { return; }
    const updateTimer = () => {
      var totalTime = dayjs().diff(dayjs(currentTimeLog.createdAt));
      var elem = currentTaskTimerRef.current;
      if (elem) {
        elem.innerHTML = millisecondsToTime(totalTime);
      }
    };
    var interval = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [currentTaskTimerRef.current, currentTimeLog]);

  const stopTracking = async () => {
    await patch(`/users/${user.id}/time-logs`, { stoppedAt: new Date() }, {
      id: currentTimeLog.id
    });
    const timeLog = await get(`/users/${user.id}/time-logs`, {
      where: { id: currentTimeLog.id }
    });
    dispatch(setCurrentTimeLog(null));
  }

  return (
    <Header style={{ gap: "20px", backgroundColor: "#e8e8e8", marginBottom: "5px", justifyContent: "space-between" }}>
      { list && <Flex className="header-left" align="center" gap={10}>
        { list.project && `${list.project.name} - ${list.name}`}
        <Tooltip placement="bottom" title="All Mode">
          <div>
            <AvatarPicker readOnly={readOnly} defaultSelectedUsers={ list.listAccesses ? list.listAccesses.filter(la => la.access && la.userId !== user.id).map(la => la.userId) : [] } users={
              users.filter(u => list.listAccesses ? !list.listAccesses.find(la => la.userId === u.id && la.access === false) : true )
            } onAddUser={ (u)=>{ onAddUser(u, list, true) }} onRemoveUser={ (u)=>{ onRemoveUser(u, list) }}/>
          </div>
        </Tooltip> |
        <Tooltip placement="bottom" title="Me Mode" >
          <div>
            <AvatarPicker readOnly={readOnly} defaultSelectedUsers={ list.listAccesses ? list.listAccesses.filter(la => !la.access && la.userId !== user.id).map(la => la.userId) : [] } users={
              users.filter(u => list.listAccesses ? !list.listAccesses.find(la => la.userId === u.id && la.access === true) : true )
            }  onAddUser={ (u)=>{ onAddUser(u, list, false) }} onRemoveUser={ (u)=>{ onRemoveUser(u, list) }}/>
          </div>
        </Tooltip>
      </Flex> }
      { !list && <>All Your Tasks</> }
      <Flex flex={0}>
      </Flex>
      <Flex className="header-right" gap={20} justify="center" align="center">
        {user.roles.includes("admin") && (
          <>
            {!hideStats && <>
              <Flex align='center' gap={20}>
                <Flex vertical align='end'>
                  <Statistic
                    valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                    value={settled_credits + settled_debits}
                    formatter={formatter}
                  />
                  <Statistic
                    valueStyle={{ fontSize: "14px" }}
                    value={unsettled_debits}
                    formatter={formatter}
                  />
                </Flex>
                <Flex vertical align='end'>
                  <Statistic
                    valueStyle={{ fontSize: "14px" }}
                    value={unsettled_credits}
                    formatter={formatter}
                  />
                  <Statistic
                    valueStyle={{ fontSize: "14px" }}
                    value={settled_credits + settled_debits + unsettled_debits}
                    formatter={formatter}
                    />
                </Flex>
                <Flex vertical align='end' style={{ marginTop: "-5px" }} >
                  <Statistic
                    valueStyle={{ fontSize: "18px", color: unsettled_credits > lastUnsettled ? '#3f8600' : '#cf1322' }}
                    value={unsettled_credits - last_stored}
                    formatter={formatter}
                    prefix={unsettled_credits > lastUnsettled ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                    suffix={
                      <SyncOutlined onClick={() => {
                        last_stored = unsettled_credits
                        localStorage.setItem('lastStored', last_stored);
                        localStorage.setItem('lastStoredAt', dayjs(Date.now()).format('MMM D, YYYY h:mm A'));
                        setLastStoredAt(dayjs(Date.now()).format('MMM D, YYYY h:mm A'));
                      }} />
                    }
                    />
                  <span style={{ fontSize: "12px", lineHeight: "15px" }}>{lastStoredAt}</span>
                </Flex>
              </Flex>
            </>}
            <Button
              onClick={() => setHideStats(!hideStats)}
              type="primary"
              shape="circle"
              icon={<BankOutlined />}
            />
          </>
        )}
        { currentTimeLog && <Alert style={{ maxWidth: "300px" }}
          // banner
          // icon={ <PauseCircleFilled style={{ fontSize: "20px", color: "#ff398b" }} /> }
          showIcon={false}
          closable={true}
          onClose={stopTracking}
          closeIcon={<PauseCircleFilled style={{ fontSize: "20px", color: "#ff398b" }} />}
          message={ <Flex>
              <Marquee pauseOnHover gradient={false}>
              {`${currentTimeLog.task.title} | ${currentTimeLog.task.list.name} | ${currentTimeLog.task.list.project.name} |`}&nbsp;
              </Marquee>&nbsp;&nbsp;&nbsp;
              <span style={{ whiteSpace: 'pre' }} ref={currentTaskTimerRef}>{
                millisecondsToTime(new Date() - new Date(currentTimeLog.createdAt))
              }</span>
            </Flex>
          }
        /> }
        <Button
          style={{ backgroundColor: "#5151af", fontWeight: "bold" }}
          type="primary"
          onClick={() => { handleWorkStatus("break") }}
        >Break</Button>
        <Button
          style={{ backgroundColor: "#af5151", fontWeight: "bold" }}
          type="primary"
          onClick={() => { handleWorkStatus("out") }}
        >Check Out</Button>
        <ProfileDropdown />
      </Flex>
    </Header>
  )
}

export default HeaderItem