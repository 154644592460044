import axios from "axios";
import _ from "lodash";

// Assuming lodash is needed elsewhere in your utilities
window._ = _;

const baseURL = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://api-work.olecons.com";



// Create an Axios instance
const api = axios.create({
  baseURL: baseURL,
});

export const slugify = (str = '') =>
  (str || '')
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export async function setToken(token) {
  // Set the token for all requests made by the Axios instance
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

if(localStorage.getItem('token')) {
  setToken(localStorage.getItem('token'));
}

export async function get(path, queryParams = null) {
  const res = await api.get(path, { params: { filter: JSON.stringify(queryParams) } });
  return res.data;
}

export async function post(path, data) {
  const res = await api.post(path, _.omitBy(data, _.isNull));
  return res.data;
}

export async function put(path, data, queryParams = null) {
  delete data.id;
  const res = await api.put(path, _.omitBy(data, _.isNull), { params: { where: JSON.stringify(queryParams) } });
  return res.data;
}
export async function patch(path, data, queryParams = null) {
  delete data.id;
  const res = await api.patch(path, _.omitBy(data, _.isNull), { params: { where: JSON.stringify(queryParams) } });
  return res.data;
}

export async function xdelete(path, queryParams = null) {
  const res = await api.delete(path, { params: { where: JSON.stringify(queryParams) } });
  return res.data;
}

export function sse(userId) {
  return new EventSource(baseURL+`/users/${userId}/notifications`);
}

export function generateHexLightColor(s) {
  const lightColors = [
    "#FFEB3B", // Yellow
    "#FFCDD2", // Light Red
    "#C5E1A5", // Light Green
    "#BBDEFB", // Light Blue
    "#FFCC80", // Light Orange
    "#E1BEE7", // Light Purple
    "#FFECB3", // Light Yellow
    "#B2EBF2", // Light Cyan
    "#F8BBD0", // Light Pink
    "#DCEDC8", // Pale Green
    "#FFF9C4", // Soft Yellow
    "#CFD8DC", // Light Gray-Blue
    "#B3E5FC", // Light Sky Blue
    "#FFAB91", // Soft Orange
    "#FF8A65", // Peach
    "#AED581", // Soft Green
    "#80CBC4", // Mint Green
    "#FFCCBC", // Peach Beige
    "#FFEE58", // Lemon Yellow
    "#A5D6A7", // Pale Green
  ];
  
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  const numberInRange = Math.abs(hash) % lightColors.length;
  return lightColors[numberInRange];
}

export function generateHexDarkColor(s) {
  const darkColors = [
    "#34495E",
    "#16A085",
    "#27AE60",
    "#2980B9",
    "#8E44AD",
    "#2C3E50",
    "#F39C12",
    "#D35400",
    "#C0392B",
    "#BDC3C7",
    "#7F8C8D",
    "#2ECC71",
    "#E67E22",
    "#3498DB",
    "#9B59B6",
    "#1ABC9C",
    "#34495E",
    "#2E4053",
    "#1B4F72",
    "#7D3C98",
    "#1F618D",
    "#117A65",
    "#148F77",
    "#76448A",
    "#B03A2E",
    "#78281F",
    "#4A235A",
    "#154360",
    "#1B2631",
    "#626567",
    "#4D5656",
    "#5F6A6A",
    "#641E16",
    "#512E5F",
    "#4A235A",
    "#7B7D7D",
    "#784212",
    "#6E2C00",
    "#1B4F72",
    "#1A5276",
    "#21618C",
    "#2874A6",
    "#2E86C1",
    "#5499C7",
    "#5DADE2",
    "#48C9B0",
    "#45B39D",
    "#52BE80",
    "#58D68D",
    "#F4D03F",
  ];
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  const numberInRange = Math.abs(hash) % darkColors.length;
  return darkColors[numberInRange];
}
